<template>
  <v-toolbar class="mb-2">
    <v-menu>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" text>
          Music Admin: {{ currentLink }}
          <v-icon right>fas fa-caret-down</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="{ title, link } in links" :key="link" :to="link" exact>
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <slot name="left"></slot>
    <v-spacer></v-spacer>
    <slot name="right"></slot>
    <v-menu>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" outlined class="ml-2">
          {{ termLabel }}
          <v-icon right>fas fa-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list style="padding:0">
        <v-list-item v-for="{ text, value } of terms" :key="'term-' + value" :disabled="term === value" @click="term = value">
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-toolbar>
</template>
<style scoped>
.v-list {
  padding: 0
}
.v-list.v-sheet.theme--light >>> .v-list-item--active {
  color: #193264
}
.v-list.v-sheet.theme--dark >>> .v-list-item--active {
  color: #C3DFF8
}
</style>
<script>
import { computed, ref, onMounted } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const path = computed(() => root.$route.path)
    const links = ref([
      { title: 'Dashboard', link: '/student/music/admin' },
      { title: 'Juries', link: '/student/music/admin/jury' },
      { title: 'Jury Setup', link: '/student/music/admin/setup' },
      { title: 'Events', link: '/student/music/admin/events' },
      { title: 'Students', link: '/student/music/admin/students' }
    ])
    const currentLink = computed(() => {
      const rec = links.value.find(({ link }) => link === path.value)
      if (rec) return rec.title
      return ''
    })
    const term = computed({
      get: () => root.$store.state.student.music.admin.term,
      set: (term) => root.$store.commit('student/setMusicAdmin', { term })
    })
    const terms = ref([])
    const selectedTerm = computed(() => terms.value.find(({ value }) => value === term.value))
    const termLabel = computed(() => selectedTerm.value ? selectedTerm.value.text : '')

    onMounted(async () => {
      if (terms.value.length === 0) {
        const dt = new Date()
        dt.setDate(dt.getDate() + 100)
        const { data } = await root.$feathers.service('system/term').find({ query: { start: { $lte: dt }, type: 'Traditional', term: { $regex: '(05|60)$' }, $sort: { start: -1 } } })
        terms.value = data.map(({ term, label }) => { return { text: label, value: term } })
        if (term.value === '') term.value = data[0].term
      }
    })

    return {
      path,
      links,
      currentLink,
      term,
      terms,
      selectedTerm,
      termLabel
    }
  }
}
</script>
