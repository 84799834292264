var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"mb-2"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_vm._v(" Music Admin: "+_vm._s(_vm.currentLink)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fas fa-caret-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.links),function(ref){
var title = ref.title;
var link = ref.link;
return _c('v-list-item',{key:link,attrs:{"to":link,"exact":""}},[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)}),1)],1),_vm._t("left"),_c('v-spacer'),_vm._t("right"),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"outlined":""}},on),[_vm._v(" "+_vm._s(_vm.termLabel)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fas fa-chevron-down")])],1)]}}])},[_c('v-list',{staticStyle:{"padding":"0"}},_vm._l((_vm.terms),function(ref){
var text = ref.text;
var value = ref.value;
return _c('v-list-item',{key:'term-' + value,attrs:{"disabled":_vm.term === value},on:{"click":function($event){_vm.term = value}}},[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }